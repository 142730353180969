<template>
  <b-card>
    <validation-observer ref="change-password">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="old-password"
            >
              <b-input-group>
                <b-form-input
                  id="old-password"
                  v-model="passwords.old_password"
                  autocomplete="on"
                  :type="oldPasswordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="oldPasswordToggleIcon"
                    @click="toggleOldPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              label="New Password"
              label-for="new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="new-password"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="new-password"
                    ref="new-password"
                    v-model="passwords.new_password1"
                    autocomplete="on"
                    :type="password1FieldType"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Confirm Password"
              label-for="confirm-password"
              prepend
            >
              <validation-provider
                #default="{ errors }"
                name="confirm password"
                rules="required|confirmed:new-password"
              >
                <b-input-group>
                  <b-form-input
                    id="confirm-password"
                    v-model="passwords.new_password2"
                    autocomplete="on"
                    :type="password2FieldType"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div>
        <b-button
          variant="primary"
          @click="changePassword"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          Save Changes
        </b-button>
        <b-button
          class="ml-1"
          variant="outline-primary"
          @click="clearPassword"
        >
          Clear
        </b-button>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'AccountPassword',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  data() {
    return {
      required,
      isLoading: false,
      passwords: {
        old_password: '',
        new_password1: '',
        new_password2: '',
      },
      oldPasswordFieldType: 'password',
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    oldPasswordToggleIcon() {
      return this.oldPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    changePassword() {
      this.$refs['change-password'].validate().then(success => {
        if (success) {
          this.updatePassword()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    updatePassword() {
      this.isLoading = true
      const updatePassword = this.passwords
      api.postData('/auth/change_password/', true, updatePassword)
        .then(response => {
          if (response.data.status) {
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Password changed!')
          } else {
            this.notificationMessage('danger', 'Xicon', 'Error', response.data.error.message)
          }
        })
        .catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
        })
        .finally(() => {
          this.isLoading = false
          this.passwords = {
            old_password: '',
            new_password1: '',
            new_password2: '',
          }
        })
    },
    clearPassword() {
      this.passwords = {
        old_password: '',
        new_password1: '',
        new_password2: '',
      }
    },
    toggleOldPasswordVisibility() {
      this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password'
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
