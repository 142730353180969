<template>
  <div>
    <b-tabs
      pills
      card
      fill
      justified
      vertical
      class="settings-tab-list"
    >
      <b-tab
        active
      >
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="1x"
          /> General
        </template>
        <AccountGeneral />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="1x"
          /> Change Password
        </template>
        <AccountPassword />
      </b-tab>
      <b-tab disabled>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="1x"
          /> Information
        </template>
        <b-card-text>Tab contents 3</b-card-text>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <feather-icon
            icon="LinkIcon"
            size="1x"
          /> Social
        </template>
        <b-card-text>Tab contents 3</b-card-text>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <feather-icon
            icon="BellIcon"
            size="1x"
          /> Notifications
        </template>
        <b-card-text>Tab contents 3</b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AccountGeneral from '@/views/account-settings/AccountGeneral.vue'
import AccountPassword from '@/views/account-settings/AccountPassword.vue'

export default {
  name: 'Settings',
  components: {
    AccountGeneral,
    AccountPassword,
  },
}
</script>

<style lang="scss">
.settings-tab-list {
  .user-img {
    width: 150px;
  }

  .nav-pills .nav-link, .nav-tabs .nav-link {
    justify-content: left;
  }
}
</style>
