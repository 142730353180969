<template>
  <b-card>
    <b-row v-if="accountInfo && accountInfo.primary_traveller">
      <b-col
        md="12"
        class="d-flex align-items-center mb-1"
      >
        <b-img
          thumbnail
          fluid
          :src="accountInfo.primary_traveller && accountInfo.primary_traveller.image ? accountInfo.primary_traveller.image : '@assets/images/avatars/13.png'"
          alt="user-img"
          class="user-img"
        />
      </b-col>
      <b-col md="6">
        <b-form-group
          label="First Name"
          label-for="first-name"
        >
          <b-input-group>
            <b-form-input
              id="first-name"
              v-model="accountInfo.primary_traveller.first_name"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Last Name"
          label-for="last-name"
        >
          <b-input-group>
            <b-form-input
              id="last-name"
              v-model="accountInfo.primary_traveller.last_name"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="E-mail"
          label-for="email"
        >
          <b-input-group>
            <b-form-input
              id="email"
              v-model="accountInfo.email"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Company"
          label-for="company"
        >
          <b-input-group>
            <b-form-input
              id="company"
              v-model="accountInfo.phone"
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'AccountGeneral',
  mixins: [helper],
  data() {
    return {
      accountInfo: {},
    }
  },
  mounted() {
    this.getAccountInfo()
  },
  methods: {
    getAccountInfo() {
      api.getData('account_management/user_account/', true)
        .then(response => {
          if (response.status) {
            this.accountInfo = response.data
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        })
        .catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
